import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { CardContent, Checkbox, FormControlLabel, Grid, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { TreeItem, TreeView } from '@material-ui/lab'
import { Filter } from 'containers/helper/SearchFilter'
import { 
  HrPermission, 
  RequestType, 
  UserLogs, 
  useClaimTypeListingLazyQuery, 
  useGetRolePermissionByUserQuery, 
  useLeaveTypeListingLazyQuery, 
  useOnBehalfPolicyListingByTypeQuery, 
  useReportingGroupListingQuery, 
  useSubmitOnBehalfPolicyByTypeMutation 
} from 'generated/graphql'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

export const OnBehalfPolicyListing = ({ ModuleType }: any) => {
  const dateFormat = require('dateformat')
  let history = useHistory()
  const { state }: any = useLocation()
  let user = JSON.parse(localStorage.getItem('loginInfo'))
  const [SearchText, setSearchText] = useState('')
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [expanded, setExpanded] = useState([])
  const [ReportingGroupSelected, setReportingGroupSelected] = useState([])
  const [Render, setRender] = useState(false)
  const [RequstTypeList, setRequstTypeList] = useState([])

  useEffect(() => {
    if(Render){
      setRender(false)
    }
  }, [Render])
  
  const snackFunc = (showText: string, redirect: boolean) => {
    setSnackBarMessage(showText)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect === true) {
        history.goBack()
      }
    }, 3000)
  }

  let UpdatePermission, ReadPermission;
  switch (ModuleType) {
    case RequestType.Overtime:
      ReadPermission = HrPermission.CompanySettingTmsOnBehalfPolicyRead
      UpdatePermission = HrPermission.CompanySettingTmsOnBehalfPolicyUpdate
      break;
    case RequestType.TimeOff:
      ReadPermission = HrPermission.CompanySettingTmsOnBehalfPolicyRead
      UpdatePermission = HrPermission.CompanySettingTmsOnBehalfPolicyUpdate
      break;
    case RequestType.Claim:
      ReadPermission = HrPermission.CompanySettingClaimOnBehalfPolicyRead
      UpdatePermission = HrPermission.CompanySettingClaimOnBehalfPolicyUpdate
      break;
    case RequestType.Leave:
      ReadPermission = HrPermission.CompanySettingLeaveOnBehalfPolicyRead
      UpdatePermission = HrPermission.CompanySettingLeaveOnBehalfPolicyUpdate
      break;
    default:
      break;
  }

  const {
    data: { getRolePermissionByUser } = {
      getRolePermissionByUser: [],
    },
    loading: GetPermissionLoading
  } = useGetRolePermissionByUserQuery({
    fetchPolicy: 'no-cache',
    variables: {
      hrPermissions: [
        ReadPermission,
        UpdatePermission
      ],
    },
  })

  const {
      data: { OnBehalfPolicyListingByType } = { OnBehalfPolicyListingByType: [] },
      loading: OnBehalfPolicyListLoading,
  } = useOnBehalfPolicyListingByTypeQuery({
      fetchPolicy: 'no-cache',
      variables: {
          CompanyID: state?.Company?.CompanyID || state?.companyID,
          ModuleType
      },
      onCompleted: data => {
        setReportingGroupSelected(
          data?.OnBehalfPolicyListingByType?.map(
            policy => ({
              ReportingGroupID: policy?.ReportingGroupID,
              RequestTypeIDs: policy?.RequestTypeIDs
            })
          )
        )
      }
  })

  const {
    data: { ReportingGroupListing } = {
      ReportingGroupListing: [],
    },
    loading: ReportingGroupLoading
  } = useReportingGroupListingQuery({
    variables: {},
    fetchPolicy: 'network-only',
  })

  const [
    loadLeaveType,
    {
      data: { LeaveTypeListing } = {
        LeaveTypeListing: [],
      },
      loading: LeaveTypeLoading
    }
  ] = useLeaveTypeListingLazyQuery({
    variables: {
      SubscriptionAccountID: user?.accountID
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setRequstTypeList(data?.LeaveTypeListing?.map( leave => ({
        TypeID: leave?.LeaveTypeID,
        TypeName: leave?.Name
      })))
    }
  })

  const [
    loadClaimType,
    {
      data: { ClaimTypeListing } = {
        ClaimTypeListing: [],
      },
      loading: ClaimTypeLoading
    }
  ] = useClaimTypeListingLazyQuery({
    variables: {
      SubscriptionAccountID: user?.accountID
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setRequstTypeList(data?.ClaimTypeListing?.map( claim => ({
        TypeID: claim?.ClaimTypeID,
        TypeName: claim?.Name
      })))
    }
  })

  useEffect(() => {
    switch (ModuleType) {
      case RequestType.Leave:
        loadLeaveType()
        break;
      case RequestType.Claim:
        loadClaimType()
        break;
      default:
        break;
    }
  }, [ModuleType])
  

  const [
      SubmitOnBehalfPolicyByType,
      {
        loading: SubmitOnBehalfPolicyLoading
      }
  ] = useSubmitOnBehalfPolicyByTypeMutation({
    fetchPolicy: 'no-cache',
    onError: error => {
      snackFunc(error?.graphQLErrors?.map(e => e?.message)?.join(', '), false)
    },
    onCompleted: data => {
      if(data?.SubmitOnBehalfPolicyByType){
          snackFunc('Record submit successfully!', true)
      }
    }
  })

  const onSubmit = () => {
    // Check Any Leave Type inactive/deleted
    const dataToSave = ReportingGroupSelected?.map( policy => ({
      ...policy,
      RequestTypeIDs: policy.RequestTypeIDs?.filter( id => 
        ClaimTypeListing?.map( type => type?.ClaimTypeID )?.includes(id) || 
        LeaveTypeListing?.map( type => type?.LeaveTypeID )?.includes(id)
      )
    }))

    if([RequestType.Claim, RequestType.Leave]?.includes(ModuleType) && dataToSave?.some(policy => policy?.RequestTypeIDs?.length < 1)){
      snackFunc('Select at least one leave type for the selected on behalf policy.', false)
      return
    }

    var UserLogs: UserLogs[] = []
    if(OnBehalfPolicyListingByType?.[0]?.UserLogs?.length > 0){
      UserLogs = OnBehalfPolicyListingByType?.[0]?.UserLogs?.map( log => ({
        ...log,
        __typename: undefined
      }));
    }

    UserLogs.push({
      modifiedDT: dateFormat(new Date(), 'dd mmm yyyy HH:MM TT'),
      modifiedBy: `${user?.ID} (${user?.name})`,
      columnName: 'ReportingGroupID',
      oldColumnValue: JSON.stringify(OnBehalfPolicyListingByType?.map(
        policy => ({
          ReportingGroupID: policy?.ReportingGroupID,
          RequestTypeIDs: policy?.RequestTypeIDs
        })
      )),
      newColumnValue: JSON.stringify(dataToSave),
    })

    SubmitOnBehalfPolicyByType({
      variables: {
        CompanyID: state?.Company?.CompanyID || state?.companyID,
        ModuleType,
        UserLogs,
        input: dataToSave,
      },
    })
  }

  return (
    <>
      {(ReportingGroupLoading || OnBehalfPolicyListLoading || SubmitOnBehalfPolicyLoading || LeaveTypeLoading || ClaimTypeLoading) && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.goBack()}
        smTitle={
          ModuleType === RequestType.Leave 
          ? 'Leave Management'
          : ModuleType === RequestType.Claim
            ? 'Claim Management'
            : 'Time Attendance'
        }
        title={state?.Company?.CompanyName || state?.companyName}
        routeSegments={[
          { name: '' },
          { name: '' },
          { name: 'On Behalf Policy', current: true },
        ]}
        rightRouteSegments={[
          { name: ModuleType?.replaceAll('_', ' '), current: true }
        ]}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle c-darkblue">
              {state?.Company?.CompanyName || state?.companyName}
            </span>
          </>
        }
      />

      <SearchHeader
        CompanyTitleSearch
        title="On Behalf Policy Listing"
        value={ReportingGroupListing?.length.toString()}
        search
        searchtext={SearchText}
        issearch={!!SearchText}
        onCloseAction={e => setSearchText('') }
        onChangeAction={e => setSearchText(e.target.value) }
      />

      <ContentWrapper singlesubHeader footer>
        {(user?.superUser || 
          getRolePermissionByUser?.hasOwnProperty(
            ReadPermission
        )) ? (
          <form onSubmit={onSubmit} id="submit-form">
            <TreeView
              className="tree-view-list"
              expanded={expanded}
              onNodeToggle={(event: ChangeEvent<{}>, nodeIds: string[]) => {
                setExpanded(nodeIds)
              }}
            >
              {ReportingGroupListing?.length > 0 && !Render
                ? (
                  Filter(ReportingGroupListing, SearchText)?.map( x => (
                    <TreeItem
                      key={x?.reporting_group_id}
                      nodeId={x?.reporting_group_id}
                      expandIcon={<ExpandMore style={{ fontSize: '23px' }} />}
                      collapseIcon={<ExpandLess style={{ fontSize: '23px' }} />}
                      style={{
                        background: 'white',
                        borderRadius: '5px',
                        padding: '8px',
                      }}
                      label={
                        <ListItem
                          style={{
                            padding: '5px 30px 5px 0px',
                            borderBottom: 0,
                          }}
                        >
                          <ListItemIcon style={{ minWidth: '30px' }}>
                            <Checkbox
                              color="primary"
                              edge="start"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              disabled={!(user?.superUser || getRolePermissionByUser?.hasOwnProperty(UpdatePermission))}
                              disableRipple
                              checked={ReportingGroupSelected?.find(policy => policy?.ReportingGroupID === x?.reporting_group_id)}
                              onClick={e => e.preventDefault()}
                              onChange={(e) => {
                                setReportingGroupSelected(prev =>
                                  e.target.checked
                                    ? [
                                        ...prev,
                                        {
                                          ReportingGroupID: x?.reporting_group_id,
                                          RequestTypeIDs: []
                                        }
                                      ]
                                    : prev?.filter(
                                        policy => policy?.ReportingGroupID !== x?.reporting_group_id
                                      )
                                )
                                setRender(true)
                              }}
                            />
                          </ListItemIcon>

                          <ListItemText
                            primary={x?.name}
                          />
                        </ListItem>
                      }
                    >
                      {[ RequestType.Leave, RequestType?.Claim ]?.includes(ModuleType) && (
                        <CardContent>
                          <Grid container>
                            {
                              RequstTypeList?.length > 0
                              ? RequstTypeList?.map( type => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6} //2
                                  md={4} //3
                                  lg={3} //4
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        size='small'
                                        color="primary"
                                        edge="start"
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                        disabled={!(user?.superUser || getRolePermissionByUser?.hasOwnProperty(UpdatePermission)) || !ReportingGroupSelected?.find(policy => policy?.ReportingGroupID === x?.reporting_group_id)}
                                        disableRipple
                                        checked={ ReportingGroupSelected?.find(policy => policy?.ReportingGroupID === x?.reporting_group_id)?.RequestTypeIDs?.includes(type?.TypeID) }
                                        onClick={e => e.preventDefault()}
                                        onChange={(e) => {
                                          setReportingGroupSelected(prev => 
                                            prev?.map(policy => {
                                              return policy?.ReportingGroupID === x?.reporting_group_id
                                              ? {
                                                ...policy,
                                                RequestTypeIDs: e.target.checked 
                                                  ? [
                                                    ...policy?.RequestTypeIDs,
                                                    type?.TypeID
                                                  ] 
                                                  : policy?.RequestTypeIDs?.filter(
                                                    TypeID => TypeID !== type?.TypeID
                                                  )
                                              }
                                              : policy
                                            })
                                          )
                                          setRender(true)
                                        }}
                                      />
                                    }
                                    label={
                                      <span className='xsTitle'>
                                        {type?.TypeName}
                                      </span>
                                    }
                                  />
                                </Grid>
                              ))
                              : (
                                <EmptyList title={`No ${ModuleType} type(s) found`} subtitle='under this subscription'/>
                              )
                            }
                          </Grid>
                        </CardContent>
                      )}
                    </TreeItem>
                  ))
                )
                : !ReportingGroupLoading && (
                  <EmptyList title="No Reporting Group Found"/>
                )
              }
            </TreeView>
          </form>
        ) : !GetPermissionLoading && (
          <EmptyList title='No Access Permission' subtitle='to view on behalf policy'/>
        )}
      </ContentWrapper>
      <Footer
        options={[
          {
            name: 'Save',
            onClick: () => onSubmit(),
            color: 'primary',
            disabled: !(
              (user?.superUser ||
                getRolePermissionByUser?.hasOwnProperty(
                UpdatePermission
              ))
            ),
          },
        ]}
      />

      <SnackBarMsg open={openSnackBar} message={snackBarMessage} />
    </>
  )
}